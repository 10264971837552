<template>
	<div>
		<feature-screen image="/views/about/resultastic/bibkeeper.jpg" lang-prefix="view.resultastic.bibkeeper">
			<div>
				<chip color="success" :to="$web('resultastic.pricing')" large>
					{{ $t('label.price_per_participant', {price: getFeatureFeePerParticipantInEuro(featureValues.Timekeeping)}) }}, {{ $t('label.additional_taxes') }}
				</chip>
			</div>

			<template #cta>
				<btn :to="$web('resultastic.features.index')" shadow x-large exact :icon="icons.back" :label="$t('action.to_overview')"/>
				<btn @click="scrollToContent" color="primary" shadow x-large :label="$t('action.learn_more')" :icon="icons.down" icon-right/>
			</template>
		</feature-screen>

		<container id="content" class="divide-y dark:divide-neutral-700">

			<feature-section cols="7" lang-prefix="view.resultastic.bibkeeper.content.section_1">

				<cdn-image src="/views/about/resultastic/bibkeeper-phone.png"
						   :alt="$t('view.resultastic.bibkeeper.content.section_1.title')"
						   :max-width="1143"
						   :size="{width: 320, ratio: 1114/909, sm: {width: 420}, xl: {width: 520}}"/>

			</feature-section>

			<feature-section cols="7" lang-prefix="view.resultastic.bibkeeper.content.section_2" right :center-content="false">

				<div class="flex items-center justify-center w-full">
					<image-modal :src="[
                {path: '/mocks/resultastic/bibkeeper-start-ipad-air.png', title: 'Start', size: {width: 340, ratio: 1600 / 2200, sm: {width: 480}}, preview: {size: {width: 340, ratio: 1600 / 2200, sm: {width: 480}}}},
                {path: '/mocks/resultastic/bibkeeper-finish-ipad-air.png', title: 'Finish', size: {width: 340, ratio: 1600 / 2200, sm: {width: 480}}, preview: {size: {width: 340, ratio: 1600 / 2200, sm: {width: 480}}}},
                ]" :slides-per-view="1"/>
				</div>
			</feature-section>

			<feature-section cols="7" lang-prefix="view.resultastic.bibkeeper.content.section_3" >

				<cdn-image src="/views/about/resultastic/6-spoferan-resultastic-timing.png"
						   :alt="$t('view.resultastic.bibkeeper.content.section_3.title')"
						   :max-width="1340"
						   :size="{width: 320, ratio: 1340/892, sm: {width: 420}, xl: {width: 520}}"/>

				<template #cta>
					<btn @click="resultasticStore.showBookingModal=true" color="primary" x-large outlined :label="$t('action.contact_us')"/>
					<btn :to="$web('resultastic.index')" color="primary" x-large :label="$t('action.learn_more')" :icon="icons.next" icon-right/>
				</template>
			</feature-section>
		</container>

	</div>
</template>

<script setup lang="ts">
import {mdiChevronDown} from "@mdi/js";
import {useInfoPage} from "~/composables/useInfoPage";
import {backIcon, nextIcon} from "@spoferan/nuxt-spoferan/icons";

const {$image} = useNuxtApp();
const {t} = useI18n();
const {getFeatureFeePerParticipantInEuro, featureValues, resultasticStore} = useResultasticFeaturesInfoPage();
const {scrollToContent} = useInfoPage();
const icons = {
	back: backIcon,
	next: nextIcon,
	down: mdiChevronDown,
};

useMeta({
	title: t('view.resultastic.bibkeeper.title'),
	description: t('view.resultastic.bibkeeper.description'),
	image: $image.format('/views/about/resultastic/bibkeeper.jpg', {width: 1200, height: 1200})
})

</script>